<template>
  <ion-page id="couponDetail">
    <ion-content :fullscreen="true">
      <topbar
        :class="{
          'gradient30 fixed': !$store.state.isPC,
          'top-bar-white': $store.state.isPC,
        }"
        :bigtitle="false"
        :showBack="true"
        :showClose="false"
        :share="$store.state.isPC ? false : true"
        :shareData="shareData"
      ></topbar>

      <div class="pc-box margin-top-0 pc-box2">
        <div class="pc-min-content-pct">
          <div class="pc-page-title" v-if="$store.state.isPC">eCoupon</div>
          <div class="content pad-60">
            <div :class="{ 'pc-content-flex': $store.state.isPC }">
              <div class="coupon-img" :style="imgStyle">
                <img v-lazy="couponDetail.img" :key="couponDetail.img" />
              </div>
              <div class="coupon-info">
                <div class="coupon-type">
                  <img src="@/assets/couponIcon.png" />
                  <span>E-Coupon</span>
                </div>

                <div class="coupon-name main-color font-24 Bold pc-font-28">
                  <span>{{ couponDetail.name }}</span>
                </div>

                <div class="hr"></div>
                <div class="period">
                  <label class="Bold pc-font-28">Redemption Period</label>
                  <span class="font-16">
                    <template v-if="$store.state.form.token">
                      {{
                        formatDate(
                          couponDetail.collectDate,
                          $store.state.form.merchantDetail.dateFormat
                        )
                      }}
                    </template>
                    <template v-else>
                      {{
                        formatDate(
                          couponDetail.redeemStartTime,
                          $store.state.form.merchantDetail.dateFormat
                        )
                      }}
                    </template>
                    -
                    {{
                      formatDate(
                        couponDetail.redeemEndTime,
                        $store.state.form.merchantDetail.dateFormat
                      )
                    }}
                  </span>
                </div>
                <div class="period">
                  <label class="Bold pc-font-28">Promotion Runs</label>
                  <span class="font-16">
                    <template v-if="$store.state.form.token">
                      {{
                        formatDate(
                          couponDetail.collectDate,
                          $store.state.form.merchantDetail.dateFormat
                        )
                      }}
                    </template>
                    <template v-else>
                      {{
                        formatDate(
                          couponDetail.collectStartTime,
                          $store.state.form.merchantDetail.dateFormat
                        )
                      }}
                    </template>

                    -
                    {{
                      formatDate(
                        couponDetail.collectEndTime,
                        $store.state.form.merchantDetail.dateFormat
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>

            <div class="tandC" :class="{ 'pc-tandC': $store.state.isPC }">
              <div class="title Bold pc-font-28">Offer details</div>
              <showMore
                :content="couponDetail.descr"
                :showHeight="!$store.state.isPC ? 70 : 68"
              ></showMore>
            </div>
            <!-- 
            <div class="tandC" :class="{'pc-tandC': $store.state.isPC}">
              <div class="title Bold pc-font-28">Terms & Conditions</div>
              <showMore :content="couponDetail.descr" :showHeight="80"></showMore>
            </div> -->

            <div
              class="btn-group"
              v-if="couponDetail.canRedeem && $store.state.appVersion"
            >
              <ion-button color="primary" @click="redeemCoupon">Redeem</ion-button>
            </div>
          </div>
          <div class="height50"></div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import config from "../config";
import topbar from "@/components/topbar.vue";
import showMore from "@/components/showMore.vue";

export default {
  name: "couponDetail",
  mixins: [config.globalMixin],
  data() {
    return {
      couponDetail: {},
      scrollTop: 0,
      imgStyle: "",
      shareData: {},
    };
  },
  components: { IonContent, IonPage, topbar, showMore, IonButton },
  methods: {
    async init() {
      const objdata = this.getAuthObj();
      objdata.couponId = this.$route.params.id;
      objdata.apiUrl = this.apiConfig.getCouponDetail;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.couponDetail = data.data.result;
      this.couponDetail.collectStartTime = this.couponDetail.collectStartTime.replaceAll(
        "-",
        "/"
      );
      this.couponDetail.collectEndTime = this.couponDetail.collectEndTime.replaceAll(
        "-",
        "/"
      );
      this.couponDetail.redeemStartTime = this.couponDetail.redeemStartTime.replaceAll(
        "-",
        "/"
      );
      this.couponDetail.redeemEndTime = this.couponDetail.redeemEndTime.replaceAll(
        "-",
        "/"
      );
      this.shareData = {
        title: this.$store.state.appName + " - Download and Enjoy Exclusive Offers",
        description: `[ACT NOW] ${this.couponDetail.name} coupon available until stocks last! Download ${this.$store.state.appName}  and grab it now.`,
        image: this.couponDetail.img,
      };
    },
    showMore(type) {
      console.log(this.$refs[type]);
      this.$refs[type].style.height = "auto";
    },
    redeemCoupon() {
      // this.redeemCodeEvent();
      this.$router.push({
        path: "/wallet",
      });
      this.sendGAEvent("Click", "Coupon Detail", "Redeem");
    },
    async redeemCodeEvent() {
      const objdata = this.getAuthObj();
      objdata.userCouponId = this.couponDetail.userCouponId;
      objdata.apiUrl = this.apiConfig.redeemUserCoupon;
      const data = await this.$store.dispatch("apiPutEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
    },
  },
  ionViewWillEnter() {
    this.init();
    let windowheight = window.innerWidth;
    if (windowheight > 600) {
      windowheight = 600;
    }
    this.$store.state.exitApp = false;

    this.sendGAPageView("eCoupon Detail");
    // this.imgStyle = "height:" + (windowheight * 52) / 102 + "px";
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.$store.state.getNotification = null;
    }, 2000);
  }
};
</script>
